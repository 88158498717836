.App {
  background: url("/baby_shower.png") no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.infoContainer {
  position: relative;
  top: 74%;
  left: 28%;
  background-color: transparent;
}

.btn {
  background-color: rgba(169, 233, 230, 0.26);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  position: flex;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  padding: 10px 12px;
  border: 1px solid white;
  cursor: pointer;
  margin: 18px;
  display: inline;
  align-items: center;
  border-radius: 5px;
}

.btn:hover {
  background-color: transparent;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.btn.active {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}